import * as React from 'react'
import { Wrapper } from '../layout'
import theme from '../styles/theme'
import GlobalStyles from '../styles/globalStyles'
import Head from '../components/Head'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { BarraNavegacion } from '../components/BarraNavegacion'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../styles/styleUtils'
import { PieDePagina } from '../components/PieDePagina'

interface IndexPageProps {
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
  }
}

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
`

const ImagenQuienesSomos = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  animation: ${scale} 5s forwards;
  ${media.md`
  `}
`

const WrapperImageQuienesSomos = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 5%;
  ${media.md`
  `}
`

const TituloSeccion = styled.h3`
  position: absolute;
  bottom: 3.5rem;
  color: white;
  font-weight: 900;
  right: 3.5rem;
  font-size: 5rem;
  text-align: center;
  text-shadow: 3px 3px grey;
  z-index: 2;
  ${media.md`
    font-size: 2rem;
  `}
  ${media.sm`
    font-size: 1.5rem;
    bottom: 1.5rem;
    right: 1.5rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

export default ({ data, location }: IndexPageProps) => {
  const ImageQuery = useStaticQuery(graphql`
    query ImageQuery {
      QuienesSomos: file(
        relativePath: { eq: "imagenes/andoni-san-juan-elena-chema.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Wrapper>
        <GlobalStyles />
        <Head pathname={location.pathname} />
        <ThemeProvider theme={theme}>{data}</ThemeProvider>
        <BarraNavegacion />
        <WrapperBody>
          <WrapperImageQuienesSomos>
            <TituloSeccion>Tercera generación desde 1956</TituloSeccion>
            <ImagenQuienesSomos
              alt={
                'Plantilla del Restaurante Borrasco de Lerín, Chema, Elena y Andoni San Juan'
              }
              fluid={ImageQuery.QuienesSomos.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
            ></ImagenQuienesSomos>
          </WrapperImageQuienesSomos>
          <Parrafo>
            Restaurante Borrasco abre sus puertas en Lerín en 1956. Su nombre
            rinde homenaje a José San Juan “Borrasco”, quien inició la tradición
            familiar junto a su esposa, Luisa Pitillas, en la calle Mayor. Donde
            se podía degustar su exquisita menestra de verduras, sus típicos
            espárragos frescos con huevos escalfados y, por supuesto, sus
            cazuelicas de conejo y ajoarriero con caracoles y los menudicos de
            cordero.
          </Parrafo>
          <Parrafo>
            Tras 36 años dedicados a nuestro público, nos trasladamos a la Calle
            Abadejo nº 2. Un local más amplio, moderno y acogedor dedicado
            exclusivamente a la restauración.
          </Parrafo>
          <Parrafo>
            Somos ya la tercera generación al frente del negocio, intentando
            superarnos día a día. A lo largo de estos años, hemos conservado en
            nuestra carta los platos tradicionales del primer chef a la vez que
            hemos ido introduciendo nuevos platos basados en las verduras de la
            huerta, los pescados frescos de temporada y las carnes de la región,
            en una combinación de tradición y modernidad con un toque de
            creatividad. Como nuestra especialidad, los espárragos rellenos, o
            nuestras exquisitas manitas de cerdo rellenas de foie y jamón
            ibérico. Todo ello con la intención de que nuestros clientes
            disfruten en la mesa.
          </Parrafo>
        </WrapperBody>

        <PieDePagina />
      </Wrapper>
    </>
  )
}
